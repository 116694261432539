@import '@/styles/new-variables';

.navlabel {
  font-size: 1rem;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 1rem;
}

.navitem {
  transition: all 0.2s;

  & .promoCodeItem {
    padding: 0;
    font-size: 1rem;

    display: inline-flex;
    background-image: $main-gradient;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &:hover {
    .navlink {
      color: $white;
    }
  }
  &:not(:last-child) {
    margin-bottom: 0.625rem;
  }
}

.navlink {
  color: #c5c5c5;
  font-size: 1rem;
  font-weight: 400;
  line-height: 130%;
}

.navwrapper {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  @media (max-width: 337px) {
    flex-direction: column;
    gap: 0.5rem;
  }
}

.column {
  flex-grow: 1;
}