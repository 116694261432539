@import '@/styles/new-variables';
@import '@/styles/functions';

.footerContactsTitle {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 1rem;
}

.contactList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;

  @media (min-width: 1200px) {
    display: grid;
  }
}

.contactItem {
  @media (min-width: 1200px) {
    &:not(:last-child) {
      margin-bottom: toRem(10);
      padding-bottom: toRem(10);
      border-bottom: 1px solid #272729;
    }
  }
}
