@import '@/styles/variables';

.backdropBase {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: $backdrop-z-index;
}
