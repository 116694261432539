@import '@/styles/variables';
@import '@/styles/functions';

.searchFormWrapper {
  width: 100%;

  .searchForm {
    max-width: 100%;
    width: 100%;
    background: $grey-1;
    border-radius: 0.75rem;
    position: relative;

    & > label {
      margin-bottom: 0;
    }
  }

  .searchInput {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

.spinner {
  position: absolute;
  top: 50%;
  right: 3rem;
  transform: translateY(-50%);
}

.toggler {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  display: inline-flex;
  padding: 0.25rem;
  align-items: center;
  border-radius: 50%;
  background: var(--transparrent-white-white-10, rgba(255, 255, 255, 0.1));
}

.suggestedWrapper {
  overflow: hidden;
  width: 100%;
  position: fixed;
  top: calc($mobile-main-header-height + 12px);
  left: 0;
  right: 0;
  border-radius: 20px;
  background: var(--Greys-Grey-1, #19191b);
  backdrop-filter: blur(15px);

  @media (min-width: 576px) {
    width: 100%;
    position: absolute;
    top: $desktop-main-header-height;
    left: 0;
  }
}

.subtitle {
  padding: 1rem 1rem toRem(4);
  color: $grey-4;
  font-size: toRem(14);
  font-weight: 400;
  line-height: 130%; /* 18.2px */
}

.suggestions {
  cursor: pointer;
}
