@import '@/styles/variables';

.dialogue {
  margin-bottom: 1rem;
}

.title {
  color: var(--Greys-Grey-3, #474749);
  font-size: 14px;
  font-weight: 500;
  line-height: 130%; /* 18.2px */
  margin-bottom: 12px;
}

.optionDetails {
  margin-bottom: 1rem;

  & p:not(:last-child) {
    margin-bottom: 0.2rem;
  }

  & span {
    color: $light-dimmed-color-3;
  }
}

.warningText {
  color: $danger-color;
}
