@import '@/styles/new-variables';

.socialCard{
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 16px 25px;
    border-radius: 12px;
    border: 1px solid var(--Greys-Grey-2, #272729);
    background: $grey-1;
    transition: all 0.3s;

    &:hover{
        background:  rgba(255, 255, 255, 0.06);
        border: 1px solid  rgba(255, 255, 255, 0.06);
    }

    .icon{
        border-radius: 8px;
        background:  rgba(255, 255, 255, 0.06);
        padding: 6px;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .info{

    }

    .title{
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 130%;
        margin-bottom: 0.25rem;
    }

    .label{
        width: 150px;
        color:  rgba(255, 255, 255, 0.30);
        font-size: 0.75rem;
        line-height: 130%; 
        overflow:hidden;
        display:inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}