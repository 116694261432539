@import '@/styles/variables';

.searchResultsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: toRem(12);
  font-size: toRem(14);
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  color: $grey-4;

  .searchResultsButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 0.6rem;
    color: $light-color-1;
    background-color: $suggested-list-search-results-button-bg-color;
    border-radius: 1rem;
    color: $primary;

    &.highlighted {
      color: $primary;
      background: $transparent-yellow-10;
      backdrop-filter: blur(10px);
    }
  }
}
