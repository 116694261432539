%text {
  color: rgba(255, 255, 255, 0.3);
  font-size: 0.75rem;
  line-height: 130%;
}

.footerBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1.25rem 0;

  .copyright {
    @extend %text;
  }
  
  .links {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    flex-wrap: wrap;
    .item {
      .link {
        @extend %text;
      }
    }
  }
}
