@import '@/styles/functions';
@import '@/styles/new-variables';

.navLink {
  display: flex;
  padding: toRem(6) toRem(8);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  color: $transparent-white-50;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 130%; /* 0.975rem */
  transition: color 0.3s;

  &:hover {
    color: $primary;
  }
}

div.modalCenterInMobile{
    top: 50%;
    left: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
}
