@import '@/styles/variables';

.suggestedDropdown {
  .suggestionsList {
    border-radius: 4px 4px 0 0;
    overflow: hidden;
  }

  .suggestionsListItem {
    &:not(:last-child) {
      border-bottom: 1px solid #252525;
    }
  }

  .emptyContent {
    padding: 2rem;
  }

  .placeholder {
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px 4px;
    color: $light-dimmed-color-2;
    background-color: $suggested-list-bg-color;
  }

  @media (min-width: 576px) {
    padding: 1rem;
  }
}
