@import '@/styles/variables';

.searchBar {
  flex-grow: 1;
  position: relative;

  &.visible {
    z-index: $searchbar-z-index;
  }
}

.backdrop {
  background-color: $backdrop-bg-color;
}
