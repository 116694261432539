@import '@/styles/variables';
@import '@/styles/new-variables';

.subscriptionCard {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 1rem;
  background: $grey-1;
  padding: 1rem;

  .subscriptionHeader {
    padding-bottom: 12px;

    .title {
      color: var(--Main-White, #fff);
      font-size: 20px;
      font-weight: 700;
      line-height: 130%; /* 26px */
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    .description {
      color: var(--transparrent-white-white-50, rgba(255, 255, 255, 0.5));
      font-size: 12px;
      font-weight: 400;
      line-height: 140%; /* 16.8px */
    }
  }

  .buttonsGroup {
    padding-top: 9px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--transparrent-white-white-6, rgba(255, 255, 255, 0.06));

    .subscriptionPrice {
      display: flex;
      flex-direction: column;

      .days {
        color: var(--transparrent-white-white-30, rgba(255, 255, 255, 0.3));
        font-size: 11px;
        font-weight: 400;
        line-height: 130%; /* 14.3px */
      }

      .price {
        color: var(--Main-White, #fff);
        font-size: 16px;
        font-weight: 700;
        line-height: 130%; /* 20.8px */
        text-transform: uppercase;
      }
    }
  }
  .flexEnd {
    justify-content: flex-end;
  }
}
