@import '@/styles/variables';
@import '@/styles/new-variables';
@import '@/styles/functions';

.footerApps {
  position: relative;
  border-radius: toRem(20);
  border: toRem(1) solid $grey-1;
  background: #0f0f12;
  padding: toRem(36) toRem(24) toRem(24);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  .titles {
    position: relative;
    text-align: center;

    .title {
      font-size: 1.625rem;
      font-weight: 700;
      line-height: 130%;
      margin-bottom: 0.5rem;
    }

    .subtitle {
      color: rgba(255, 255, 255, 0.3);
      text-align: center;
      font-size: toRem(13);
      line-height: 140%;
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .banner {
      position: absolute;
      left: 55%;
      transform: translateX(-50%);
      width: 80%;
      bottom: 0;
      width: 80%;
      object-fit: contain;
    }

    .circle {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }

    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(25, 25, 27, 0) 0%, #19191b 100%);
    }
  }
}

.apps {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 0.75rem;

  & > .qr {
    grid-column: 1 / 2;
    grid-row: 1 / 4;
  }

  .app,
  .qr {
    position: relative;
    display: block;
    border-radius: toRem(16);
    background: $transparent-white-6;
    backdrop-filter: blur(toRem(15));
    transition: all 0.1s;
    border: toRem(1) solid transparent;
    min-height: toRem(44);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app {
    cursor: pointer;
    &:hover {
      border: toRem(1) solid $white;
    }
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .banner {
      position: absolute;
      left: 55%;
      transform: translateX(-50%);
      width: 80%;
      bottom: 0;
      width: 80%;
      object-fit: contain;
    }

    .circle {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }

    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(25, 25, 27, 0) 0%, #19191b 100%);
    }
  }
}

.apps {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 0.75rem;

  & > .qr {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

  .app,
  .qr {
    position: relative;
    display: block;
    border-radius: toRem(16);
    background: $transparent-white-6;
    backdrop-filter: blur(toRem(15));
    transition: all 0.1s;
    border: toRem(1) solid transparent;
    min-height: toRem(44);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .app {
    cursor: pointer;
    &:hover {
      border: toRem(1) solid $white;
    }
  }

  .qr {
    padding: 1rem;
  }
}
