@import '@/styles/variables';
@import '@/styles/functions';

.button {
  font-weight: 700;

  &.full {
    width: 100%;
  }

  @media (max-width: get-breakpoint-width('sm')) {
    width: 100%;
  }
}

.promoCode {
  .form {
    margin-left: 0;
    gap: 1rem;
  }
}

.errorMessage {
  padding: 0.25rem 0;
}
