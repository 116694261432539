.profileContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .topPanel {
    margin-bottom: 1rem;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
  }
}
.switcher {
  display: flex;
  padding: 6px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  background: var(--transparrent-white-white-10, rgba(255, 255, 255, 0.1));
  backdrop-filter: blur(10px);
  font-size: 14px;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
}

.activeSwitcher {
  border-radius: 10px;
  background: var(--transparrent-yellow-yellow-10, rgba(255, 255, 77, 0.1));
  backdrop-filter: blur(10px);
}
