.title {
  max-width: 200px;
  margin: 1.5rem 0;
  min-height: 18px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 171px;
  gap: 20px;
  margin-bottom: 20px;
}

.card {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 1.125rem 1.3rem;
}
