@import '@/styles/variables';

.mainFooter {
  background-color: $dark-color-1;
  border-top: 2px solid rgba(255, 255, 77, 0.1);
  padding-top: 2rem;

  .logo {
    margin-bottom: 2rem;
  }

  .blocks {
    display: grid;
    gap: 1.25rem;

    @media (min-width: 1200px) {
      grid-template-columns: 280px repeat(3, 1fr) auto;
      grid-template-rows: auto auto;

      & > *:nth-child(1) {
        grid-column: 1/5;
        grid-row: 1/2;
      }

      & > *:nth-child(2) {
        grid-column: 1/2;
        grid-row: 2/3;
      }

      & > *:nth-child(3) {
        grid-column: 2/5;
        grid-row: 2/3;
      }

      & > *:nth-child(4) {
        grid-column: 5/6;
        grid-row: 1/3;
      }
    }
  }
}

@media (max-width: 1200px) {
  .mainFooter {
    padding-bottom: calc(85px + env(safe-area-inset-bottom));
  }
}
