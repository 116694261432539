@import '@/styles/new-variables';
@import '@/styles/functions';

.contactCard {
  display: flex;
  align-items: center;
  gap: toRem(17);
  transition: all 0.3s;

  @media (max-width: 1200px) {
    padding: 1rem toRem(25);
    border-radius: toRem(12);
    border: 1px solid $grey-2;
    background: $grey-1;
    transition: all 0.3s;

    &:hover {
      background: $transparent-white-6;
      border: 1px solid $transparent-white-6;
    }
  }

  &:hover {
    transition: all 0.3s;
    .title {
      color: $primary;
    }
  }

  .icon {
    color: $primary;
    flex: 0 0 toRem(24);
    height: toRem(24);
  }

  .title {
    font-size: 0.75rem;
    line-height: 130%;
    color: rgba(255, 255, 255, 0.3);
    margin-bottom: 0.25rem;
    transition: all 0.3s;
  }

  .label {
    font-size: 1rem;
    line-height: 130%;
  }
}
